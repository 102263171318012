import { useRecordContext } from "react-admin";
import { makeStyles } from "@mui/styles";

import LanguageNameField from '../../../../components/language-name-field';
import { FlattenedArrayField } from '../../../../components';

const { REACT_APP_WEB_URL: webUrl } = process.env;
const styles = {
  inline: { display: "inline-block" },
  chip: { marginRight: 3 },
};
const useStyles = makeStyles(styles);

export const DirectLink = ({ comp, path }) => {
  const record = useRecordContext();
  const link = `${webUrl}/user/${record.alias}${path ? `/${path}` : ""}`;
  return <a href={link}>{link}</a>;
};


export const LanguageNameArrayField = (props) => {
  const record = useRecordContext()
  const classes = useStyles();

  if(record.speaksLanguages === undefined) {
    record.speaksLanguages = ['en']
  }

  return (<FlattenedArrayField singleComp={(item) => <LanguageNameField className={classes.chip} key={item} languageName={item} />} { ...props} addLabel />)
}

export const UserName = () => {
  const record = useRecordContext();

  return `${record.firstName} ${record.lastName}`;
};

export const AttachedLink = (props) => {
  const record = useRecordContext();
  const url = record[props.source];

  return (
    <a href={url} target="_blank">
      Open
    </a>
  );
};

export const DebugField = () => {
  const record = useRecordContext();
  const input = {
    ...record,
    eventInstances: undefined,
    eventInstancesIds: undefined
  }
  
  return <pre style={{ fontSize: 12 }}>{JSON.stringify(input, null, 2)}</pre>;
};
