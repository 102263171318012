import { useRecordContext } from 'react-admin';

const FlattenedArrayField = (props) => {
  const record = useRecordContext();
  const { singleComp, source } = props;
  const array = record[source];

  if (typeof array === "undefined" || array === null || array.length === 0) {
    return <div />;
  } else {
    return <>{array.map((item) => singleComp(item))}</>;
  }
};

export default FlattenedArrayField;