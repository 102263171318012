import {
  TextInput,
  RadioButtonGroupInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  useChoicesContext,
  NumberInput,
} from "react-admin";
import { Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DateInput } from '../../components/_DateInput';
import { dateToTimestamp } from '../../utils'

const UserNameSelectArrayInput = (props) => {
  const { allChoices } = useChoicesContext();
  const mappedChoices = (allChoices ?? []).map((choice) => ({
    id: choice.id,
    name: `${choice.firstName} ${choice.lastName}`,
  }));

  return (
    <SelectArrayInput
      {...props}
      source="myTeam"
      optionText="name"
      choices={mappedChoices}
      label="My Team"
    />
  );
};

const styles = {
  twoColLeft: { display: "inline-block" },
  twoColRight: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const UserForm = (props) => {
  const classes = useStyles();
  const { isEdit } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Basics">
        <TextInput
          source="firstName"
          formClassName={classes.twoColLeft}
          label="First Name"
        />
        <TextInput
          source="lastName"
          formClassName={classes.twoColRight}
          label="Last Name"
        />
        {!isEdit && <TextInput source="email" label="E-mail" />}
        <TextInput source="phoneNumber" label="Phone Number" />
        <RadioButtonGroupInput
          source="gender"
          choices={[
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
          ]}
        />
        <DateInput source="dateOfBirthTimestamp" parse={dateToTimestamp} />
        <RadioButtonGroupInput
          source="billingMode"
          choices={[
            { id: "cc", name: "Credit Card" },
            { id: "direct", name: "Direct" },
          ]}
        />
        <Card style={{ backgroundColor: "#ffa7a1" }}>
          <CardContent>
            <BooleanInput source="isAdmin" label="Is Admin?" />
          </CardContent>
        </Card>
      </FormTab>

      <FormTab label="Matching Criteria">
        <NumberInput source="numDogs" label="Dogs" />
        <NumberInput source="numCats" label="Cats" />
        <BooleanInput source="smokingHousehold" label="Smoking Household" />
        <TextInput
          source="specialNeeds"
          label="Children's Special Needs"
        />
        <TextInput source="dietaryRestrictions" label="Dietary Restrictions" />
        <ArrayInput source="children" label="Children">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <DateInput
              source="birthdayTimestamp"
              label="Birthday"
              parse={dateToTimestamp}
            />
            <RadioButtonGroupInput
              source="gender"
              choices={[
                { id: "male", name: "Male" },
                { id: "female", name: "Female" },
              ]}
              label="Gender"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Address">
        <TextInput
          source="address.streetAddress"
          label="Street Address"
          formClassName={classes.twoColLeft}
        />
        <TextInput
          source="address.unit"
          label="Unit"
          formClassName={classes.twoColRight}
        />
        <br />
        <TextInput
          source="address.city"
          label="City"
          formClassName={classes.twoColLeft}
        />
        <TextInput
          source="address.state"
          label="State"
          formClassName={classes.twoColRight}
        />
        <TextInput source="address.zipcode" label="Zipcode" />
        <TextInput
          source="entranceInstructions"
          label="Entrance Instructions"
          fullWidth
        />
      </FormTab>
      <FormTab label="Contacts">
        <TextInput source="partner.firstName" label="Partner First Name" />
        <TextInput source="partner.lastName" label="Partner Last Name" />
        <TextInput source="partner.email" label="Partner Email" />
        <TextInput source="partner.phoneNumber" label="Partner Phone Number" />

        <ArrayInput source="emergencyContacts">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <TextInput source="relationship" label="Relationship" />
            <TextInput source="phoneNumber" label="Phone Number" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Notifications">
        <RadioButtonGroupInput
          label="Appointment Text Reminders"
          source="notificationOptOut.appointmentReminders"
          parse={(value) => (value === "true" ? true : false)}
          optionValue="value"
          choices={[
            { id: "opt-out", name: "Opt Out", value: true },
            { id: "opt-in", name: "Opt In", value: false },
          ]}
        />
      </FormTab>
      <FormTab label="My Team">
        <ReferenceArrayInput
          source="myTeam"
          reference="Provider"
          label="My Team"
        >
          <UserNameSelectArrayInput />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Admin Notes">
        <TextInput
          fullWidth
          multiline
          source="adminNotes"
          label="Admin Notes"
        />
      </FormTab>
    </TabbedForm>
  );
};

export default UserForm;
