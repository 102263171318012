import {
  Tab,
  TextField,
  DateField,
  EmailField,
  BooleanField,
  ImageField,
} from 'react-admin'
import CovidCommitmentField from '../../../../components/covid-commitment-field';
import ConditionalField from '../../../../components/conditional-field';

const TabBasics = (props) => (
  <Tab label="Basics" {...props}>
    <ImageField
      source="profilePic"
      title="Photo"
      sx={{ "& .RaImageField-image": { width: 180, height: "auto", objectFit: "contain" } }}
    />
    <TextField source="firstName" label="First Name" />
    <TextField source="lastName" label="Last Name" />
    <EmailField source="email" label="E-mail" />
    <TextField source="phoneNumber" label="Phone Number" />
    <TextField source="gender" label="Gender" />
    <DateField source="dateOfBirthTimestamp" label="Date of Birth" />
    <ConditionalField
      comp={BooleanField}
      source="hasPublicProfile"
      label="Has Public (SaaS) Profile"
    />
    <CovidCommitmentField label="COVID Commitment" />

    {/* New for becoming-provider */}
    <EmailField source="zelleEmail" label="Zelle E-mail" />
    <TextField source="zellePhoneNumber" label="Zelle Phone Number" />
    <TextField source="becomingProviderLastStep" label="Last Step of Sign Up" />
  </Tab>
);

export default TabBasics
