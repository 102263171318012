import moment from 'moment';
import { useRecordContext } from 'react-admin';

const StartDate = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }
  return `${moment(record.startDate).format('LL')} ${moment(record.startTime, 'HH:mm').format('h:mma')}`;
};

export default StartDate;
