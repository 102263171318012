import { Title } from 'react-admin';
import { Card, CardContent } from '@mui/material';

export const DashboardPage = () => {
  return (
    <Card>
      <Title title="Apiari" />
      <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>
  );
};
