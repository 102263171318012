import { useState, useCallback } from "react";
import { Edit } from "react-admin";
import CreateEditForm from "./CreateEditForm";

const UserEdit = (props) => {
  const [profilePic, setProfilePic] = useState(null);

  return (
    <Edit {...props} undoable={false} transform={(data) => ({ ...data, profilePic, revies: undefined })}>
      <CreateEditForm
        profilePic={profilePic}
        setProfilePic={useCallback((picture) => setProfilePic(picture), [setProfilePic])}
        isEdit={true}
      />
    </Edit>
  );
};

export default UserEdit;
