const url = process.env.REACT_APP_API_URL

class HttpProvider {
  constructor() {}

  async matchByHardCriteria(matchingCriteria) {
    try {
      const response = await fetch(url + "match/hard-criteria", {
        method: "POST",
        body: JSON.stringify(matchingCriteria),
      });

      return response.json();
    } catch (err) {
      throw err;
    }
  }
}

export default new HttpProvider()
