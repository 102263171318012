import { Chip } from "@mui/material";

import FlattenedArrayField from './flattened-array-field';

const ChipArrayField = (props) => {
  return (
    <FlattenedArrayField
      addLabel
      singleComp={(item) => (
        <Chip sx={{ marginRight: '8px' }} label={item} key={item} />
      )}
      {...props}
    />
  );
};

export default ChipArrayField;