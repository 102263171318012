import { Tab, TextField, Datagrid, ArrayField } from "react-admin";

import { AttachedLink, DebugField } from "../components";
import ConditionalField from "../../../../components/conditional-field";

const TabAdminNotes = (props) => (
  <Tab label="Admin Notes" {...props}>
    <TextField source="adminNotes" label="Admin Notes" />
    
    <hr />
    <ConditionalField
      comp={AttachedLink}
      source="profilePic"
      label="Profile Pic"
    />
    <ConditionalField comp={AttachedLink} source="resume" label="Resume" />
    <ConditionalField
      comp={AttachedLink}
      source="identificationPhoto"
      label="ID Photo"
    />
    <ConditionalField
      comp={AttachedLink}
      source="CPRCertificate"
      label="CPR Certificate"
    />

    <ArrayField source="references">
      <Datagrid>
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email" />
        <TextField source="phoneNumber" label="Phone Number" />
      </Datagrid>
    </ArrayField>

    <ConditionalField comp={DebugField} source="email" label="Debug" />
  </Tab>
);

export default TabAdminNotes;
