import { useState } from "react";
import {
  useShowController,
  useNotify,
} from "react-admin";
import {
  Stack,
  Box,
  InputLabel,
  Input,
  Button,
  Typography,
} from "@mui/material";

import { CustomerInfo } from '../customer-info';

import {
  ApiariSpinner,
} from "../../../../components";

export const ImportCriteriaPanel = ({ setCriteria }) => {
  const notify = useNotify();
  const [inputValue, setInputValue] = useState();
  const [request, setRequest] = useState({
    customerId: null,
    enabled: false
  });

  const { error, isLoading, record, ...rest } = useShowController({
    id: request.customerId ?? "uknown",
    resource: "Customer",
    queryOptions: {
      onSettled: () => {
        setInputValue()
        setRequest({ ...request, enabled: false });
      },
      onError: (error) => {
        notify(error.message, { type: "error" });
      },
      enabled: request.enabled,
    },
  });

  const handleSendingRequest = () => {
    setRequest({
      customerId: inputValue,
      enabled: true,
    });
  }

  const handleApplyCriteria = () => {
    setCriteria({
      numDogs: record.numDogs,
      numCats: record.numCats,
      specialNeeds: Boolean(record.specialNeeds),
      children: (record.children ?? []).map(child => ({ birthdayTimestamp: child.birthdayTimestamp })),
      excludeProviders: record.myTeam,
      geoLocation: {
        lat: record.geoLocation.lat,
        lng: record.geoLocation.lng,
      },
    });
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ApiariSpinner size={72} />
      </Box>
    );
  }

  return (
    <Stack>
      <Typography gutterBottom variant="h6" component="div">
        Import criteria
      </Typography>

      <Box sx={{ display: "flex", gap: "24px", alignItems: "flex-end" }}>
        <Box>
          <InputLabel htmlFor="customerId">Customer ID</InputLabel>
          <Input id="customerId" onChange={(event) => setInputValue(event.target.value) }/>
        </Box>
        <Button variant="contained" disabled={!inputValue} onClick={handleSendingRequest}>
          Get Customer info
        </Button>
        <Button variant="contained" disabled={!record || error} onClick={handleApplyCriteria}>
          Apply criteria
        </Button>
      </Box>

      <CustomerInfo record={record} {...rest} />
    </Stack>
  );
};
