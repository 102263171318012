import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
  CheckboxGroupInput,
} from "react-admin";
import { Card, CardContent, CardHeader } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { serviceNameList, eventTypeList } from "../../utils";
import { DateInput } from '../../components/_DateInput';

const getOptionText = (record) => {
  const { id, firstName, lastName } = record ?? {};

  if (!id) return null;

  return lastName ? `${firstName} ${lastName}` : firstName;
};

const styles = {
  extraCard: { minWidth: 550, marginBottom: 10 },
};

const useStyles = makeStyles(styles);

const EventForm = (props) => {
  const classes = useStyles();

  return (
    <SimpleForm {...props}>
      <SelectInput
        label="Event Type"
        source="eventType"
        choices={eventTypeList}
      />
      <SelectInput
        label="Service"
        source="serviceName"
        choices={serviceNameList}
      />
      <ReferenceInput
        source="customer.id"
        sort={{ field: "firstName", order: "ASC" }}
        reference="Customer"
        allowEmpty
      >
        <AutocompleteInput
          label="Customer"
          filterToQuery={(query) => ({ query })}
          shouldRenderSuggestions={(val) => val.length >= 3}
          optionText={getOptionText}
        />
      </ReferenceInput>
      <ReferenceInput
        source="provider.id"
        sort={{ field: "firstName", order: "ASC" }}
        reference="Provider"
      >
        <AutocompleteInput
          label="Provider"
          filterToQuery={(query) => ({ query })}
          shouldRenderSuggestions={(val) => val.length >= 3}
          optionText={getOptionText}
        />
      </ReferenceInput>
      <DateInput source="startDate" label="Start Date" />
      <TextInput source="startTime" label="Start Time" type="time" />
      <TextInput source="endTime" label="End Time" type="time" />

      <Card raised={true} className={classes.extraCard}>
        <CardHeader title="Recurrence Options" />
        <CardContent>
          <span>
            <i>
              Warning: editing recurrence info affects past and future sessions
            </i>
          </span>
          <br />
          <br />
          <DateInput source="event.endDate" label="End Date" />
          <SelectInput
            fullWidth
            label="Recurring?"
            source="event.recurrence.type"
            choices={[
              { id: "single", name: "Single Session" },
              { id: "weekly", name: "Recurring" },
            ]}
          />
          <SelectInput
            fullWidth
            label="Interval"
            source="event.recurrence.interval"
            choices={[
              { id: 1, name: "Every 1 Week" },
              { id: 2, name: "Every 2 Weeks" },
              { id: 3, name: "Every 3 Weeks" },
              { id: 4, name: "Every 4 Weeks" },
            ]}
          />
          <CheckboxGroupInput
            fullWidth
            label="Weekdays"
            source="event.weekdays"
            choices={[
              { id: "Sunday", name: "Sunday" },
              { id: "Monday", name: "Monday" },
              { id: "Tuesday", name: "Tuesday" },
              { id: "Wednesday", name: "Wednesday" },
              { id: "Thursday", name: "Thursday" },
              { id: "Friday", name: "Friday" },
              { id: "Saturday", name: "Saturday" },
            ]}
          />
        </CardContent>
      </Card>

      <Card raised={true} className={classes.extraCard}>
        <CardHeader title="Additional Info" />
        <CardContent>
          <TextInput source="title" label="Private Event Title" />
          <TextInput
            fullWidth
            multiline
            source="notes"
            label="Shared Notes (visible to customer and provider)"
          />
          <TextInput fullWidth source="dialInInfo" label="Dial-in Info" />
          <NumberInput
            fullWidth
            source="hourlyPrice"
            label="Hourly Price Override"
            min={0}
            step={0.01}
          />
          <SelectInput
            source="paymentMethod"
            choices={[
              { id: "cc", name: "Credit Card" },
              { id: "direct", name: "Direct" },
            ]}
          />
        </CardContent>
      </Card>
    </SimpleForm>
  );
};

export default EventForm;
