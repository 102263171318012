import { useRecordContext } from 'react-admin';

const UserNameField = () => {
  const record = useRecordContext();
  
  if (!record) {
    return '';
  }
  return `${record.firstName} ${record.lastName}`;
};

export default UserNameField;
