import { useRecordContext } from "react-admin";

export const UserTitle = () => {
  const record = useRecordContext();

  if (!record) return <span />;
  const { isAdmin, firstName, lastName, id, gender } = record;
  return (
    <span>
      {isAdmin && (gender === "female" ? "👸" : "🤴")} {firstName} {lastName} (
      {isAdmin ? "Admin" : "Customer"} #{id})
    </span>
  );
};
