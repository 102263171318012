import { useRecordContext } from 'react-admin';

const eventTypeToHuman = {
  interview: "Interview",
  appointment: "Session",
  private: "Private Appointment",
}

const EventInstanceTitle = () => {
  const record = useRecordContext();

  if (!record) {
    return <></>;
  }
  return <span>{eventTypeToHuman[record.eventType]} Details</span>;
};

export default EventInstanceTitle;
