import { useInput } from "react-admin";
import { TextField } from '@mui/material';

const metersInMile = 1609.34

const OperationRadiusInput = ({ source }) => {
  const { field } = useInput({ source });
  const value = ((field.value || 0) / metersInMile).toFixed(3)

  return (
    <TextField
      label="Operation radius"
      variant="filled"
      type="number"
      defaultValue={value}
      onChange={(event) => field.onChange(event.target.value * metersInMile)}
    />
  );
};

export default OperationRadiusInput;