import ServiceNameField from "./service-name-field";
import FlattenedArrayField from './flattened-array-field';

const ServiceNameArrayField = (props) => {
  return (
    <FlattenedArrayField
      singleComp={(item) => (
        <ServiceNameField
          style={{ marginRight: '8px' }}
          key={item}
          serviceName={item}
        />
      )}
      {...props}
    />
  );
};

export default ServiceNameArrayField;