import { Tab, NumberField } from "react-admin";

import ConditionalField from "../../../../components/conditional-field";

const TabRates = (props) => (
  <Tab label="Rates" {...props}>
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.childcare"
      label="Hourly Rate (Childcare)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.childcarePlus"
      label="Hourly Rate (Enrichment)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.virtual"
      label="Hourly Rate (Virtual)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.cleaning"
      label="Hourly Rate (Cleaning)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.night_nurse"
      label="Hourly Rate (Night Nurse)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.cooking"
      label="Hourly Rate (Cooking)"
    />
    <ConditionalField
      comp={NumberField}
      options={{ style: "currency", currency: "USD" }}
      source="prices.housekeeping"
      label="Hourly Rate (Housekeeping)"
    />
  </Tab>
);

export default TabRates;
