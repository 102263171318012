import { Tab, ArrayField, Datagrid, TextField } from "react-admin";

const TabEmergencyContacts = (props) => (
  <Tab label="Emergency Contacts" {...props}>
    <ArrayField source="emergencyContacts" addLabel={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="relationship" label="Relationship" />
        <TextField source="phoneNumber" label="Phone Number" />
      </Datagrid>
    </ArrayField>
  </Tab>
);

export default TabEmergencyContacts;
