import { Tab } from "react-admin";

import { TruthyField } from "../../../../components";

const TabNotifications = (props) => (
  <Tab label="Notifications" {...props}>
    <TruthyField
      label="Appointment Text Reminders"
      source="notificationOptOut.appointmentReminders"
      invert={true}
    />
  </Tab>
);

export default TabNotifications;
