import {
  Tab,
  TextField,
  Datagrid,
  DateField,
  ArrayField,
} from 'react-admin';

import ConditionalField from "../../../../components/conditional-field";
import { ServiceNameArrayField, ChipArrayField } from "../../../../components";


const TabProfile = (props) => (
  <Tab label="Profile" {...props}>
    <ServiceNameArrayField source="services" label="Performs Services" />
    <ConditionalField
      comp={ChipArrayField}
      source="enrichmentActivities"
      label="Enrichment Activities"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.childcare"
      label="Blurbs (Childcare)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.childcarePlus"
      label="Blurbs (Enrichment)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.virtual"
      label="Blurbs (Virtual)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.cleaning"
      label="Blurbs (Cleaning)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.nightNurse"
      label="Blurbs (Night Nurse)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.cooking"
      label="Blurbs (Cooking)"
    />
    <ConditionalField
      comp={TextField}
      source="blurbs.housekeeping"
      label="Blurbs (Housekeeping)"
    />

    <ConditionalField
      comp={TextField}
      source="about.childcare"
      label="About (Childcare)"
    />
    <ConditionalField
      comp={TextField}
      source="about.childcarePlus"
      label="About (Enrichment)"
    />
    <ConditionalField
      comp={TextField}
      source="about.virtual"
      label="About (Virtual)"
    />
    <ConditionalField
      comp={TextField}
      source="about.cleaning"
      label="About (Cleaning)"
    />
    <ConditionalField
      comp={TextField}
      source="about.nightNurse"
      label="About (Night Nurse)"
    />
    <ConditionalField
      comp={TextField}
      source="about.cooking"
      label="About (Cooking)"
    />
    <ConditionalField
      comp={TextField}
      source="about.housekeeping"
      label="About (Housekeeping)"
    />
    <ConditionalField comp={TextField} source="funFacts" label="Fun Facts" />
    <ConditionalField
      comp={DateField}
      source="lastBackgroundCheck"
      label="Last Background Check"
    />

    <ConditionalField
      comp={TextField}
      source="extraService"
      label="Extra Help you can offer families"
    />

    <ArrayField source="certificates">
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <DateField source="timestamp" label="Date" />
      </Datagrid>
    </ArrayField>
  </Tab>
);

export default TabProfile;
