import { Tab, Datagrid, ReferenceField } from "react-admin";

import { IdToArrayField, UserNameField } from "../../../../components";

const TabMyTeam = (props) => (
  <Tab label="My Team" {...props}>
    <IdToArrayField source="myTeam">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          source="id"
          reference="Provider"
          link="show"
          label="Provider"
        >
          <UserNameField />
        </ReferenceField>
      </Datagrid>
    </IdToArrayField>
  </Tab>
);

export default TabMyTeam;
