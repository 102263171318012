import { Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';

const CovidCommitmentField = ({ ...props }) => {
  const record = useRecordContext();
  const { covidCommitments } = record ?? {};

  if (!record) {
    return <></>;
  }

  if (covidCommitments && covidCommitments.length) {
    const lastCommitment = covidCommitments[covidCommitments.length - 1];
    return <span {...props}><Chip label={`Accepted ${lastCommitment.date}`} style={{ backgroundColor: '#5eb314' }} /></span>;
  }
  
  return <span {...props}><Chip label="Never" style={{ backgroundColor: '#ff2424' }}/></span>;
};

export default CovidCommitmentField;
