import { Tab } from "react-admin";
import { DirectLink } from "../components";
import ConditionalField from "../../../../components/conditional-field";

const TabLinks = (props) => (
  <Tab label="Links" {...props}>
    <ConditionalField
      comp={DirectLink}
      label="Default (Shows Enrichment profile, asks for service when booking)"
      condition={(record) =>
        record.services && record.services.includes("childcarePlus")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="childcare"
      label="Childcare"
      condition={(record) =>
        record.services && record.services.includes("childcare")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="childcarePlus"
      label="Enrichment"
      condition={(record) =>
        record.services && record.services.includes("childcarePlus")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="virtual"
      label="Virtual"
      condition={(record) =>
        record.services && record.services.includes("virtual")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="cleaning"
      label="Cleaning"
      condition={(record) =>
        record.services && record.services.includes("cleaning")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="nightNurse"
      label="Night Nurse"
      condition={(record) =>
        record.services && record.services.includes("nightNurse")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="cooking"
      label="Cooking"
      condition={(record) =>
        record.services && record.services.includes("cooking")
      }
    />
    <ConditionalField
      comp={DirectLink}
      path="housekeeping"
      label="Housekeeping"
      condition={(record) =>
        record.services && record.services.includes("housekeeping")
      }
    />
  </Tab>
);

export default TabLinks;
