import EventEdit from "./edit";
import EventList from "./list";
import EventShow from "./show";
import EventCreate from './create';

const eventComponents = {
  list: EventList,
  show: EventShow,
  edit: EventEdit,
  create: EventCreate,
};

export default eventComponents;
