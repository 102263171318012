import {
  List,
  Datagrid,
  TextField,
  useStore
} from "react-admin";

import PayrollSessionsInfo from './components/payroll-sessions-info'
import { DateInput } from '../../components/_DateInput';
import ServiceNameField from '../../components/service-name-field';
import PaginationOptions from '../../components/pagination-options';

const PayrollListFilter = [
  <DateInput source="startDate" label="Start Date" alwaysOn/>,
  <DateInput source="endDate" label="End Date" alwaysOn />
];

const payrollListStyle = {
  "& .RaDatagrid-expandedPanel > td": {
    padding: 0,
  },
};

const payrollRowStyle = (expandedRowIds) => (record, index) => ([
  {
    '&': {
      backgroundColor: expandedRowIds.includes(String(index + 1)) ? "lightblue" : "transparent",
    }
  },
  {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: expandedRowIds.includes(String(index + 1)) ? "lightblue" : "transparent"
    }
  },
  {
    '& + .RaDatagrid-expandedPanel .RaDatagrid-tableWrapper': {
      borderLeft: "3px solid #FFC43B"
    }
  }
]);

const PayrollList = (props) => {
  const [expandedIds] = useStore(`Payroll.datagrid.expanded`, []);

  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      title="Payroll"
      filters={PayrollListFilter}
    >
      <Datagrid
        expand={PayrollSessionsInfo}
        sx={payrollListStyle}
        rowSx={payrollRowStyle(expandedIds)}
      >
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="email" label="Email" />
        <ServiceNameField label="Service" />
        <TextField source="takeHomeRate" label="Take home rate" />
        <TextField source="hourlyRate" label="Hourly rate" />
        <TextField source="totalhours" label="Total hours" />
        <TextField source="totalTips" label="Total tips" />
        <TextField source="totalLateSessions" label="Total late sessions" />
        <TextField source="totalpayments" label="Total" />
      </Datagrid>
    </List>
  );
};

export default PayrollList;
