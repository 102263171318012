import { Create } from "react-admin";

import CreateEditForm from "./CreateEditForm";

const UserCreate = (props) => (
  <Create {...props}>
    <CreateEditForm redirect="show" isEdit={false} />
  </Create>
);

export default UserCreate;
