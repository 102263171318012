import {
  Datagrid,
  List,
  TextField,
  BooleanInput
} from "react-admin";

import { DateInput } from '../../components/_DateInput';
import PaginationOptions from '../../components/pagination-options';


const StatsListFilter = [
  <DateInput source="startDate" label="Start Date" alwaysOn />,
  <DateInput source="endDate" label="End Date" alwaysOn />,
  <BooleanInput source="isSubscriber" label="Subscriber" alwaysOn/>

];

const getDefaultWindow = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
  const startDate = `${year}-${month}-01`
  const endDate = `${year}-${month}-${today.getDate()}`
  return { startDate, endDate, isSubscriber: true }
}; 

const StatsList = (props) => {

  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      title="Provider Dashboard"
      filters={StatsListFilter}
      filterDefaultValues={getDefaultWindow()}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <TextField source="email" label="Email" />
        <TextField
          source="subscriptionStartDate"
          label="Subscription Start Date"
        />
        <TextField
          source="reqRec"
          label="Req/Rec"
          cellClassName="percentage-cell"
          render={(record) => `${record.reqRec}%`}
        />
        <TextField source="totalApiariSession" label="Total Apiari Sessions" />
        <TextField source="recommendations" label="Recommendations" />
        <TextField source="bookingsReceived" label="Bookings Received" />
        <TextField source="bookingsAccepted" label="Bookings Accepted" />
        <TextField source="interviewsReceived" label="Interviews Received" />
        <TextField source="interviewsAccepted" label="Interviews Accepted" />
      </Datagrid>
    </List>
  );
};

export default StatsList;
