import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { get, pick } from 'lodash';
import { Card, CardContent, CardHeader, Button, Box } from '@mui/material'

import { ThumbUp, ThumbDown } from '@mui/icons-material';

import StartDateField from '../../components/start-date-field';
import EndDateField from '../../components/end-date-field';
import EventInstanceTitle from '../../components/event-instance-title';
import ConditionalField from '../../components/conditional-field';

import {
  TextField,
  SimpleShowLayout,
  Show,
  TopToolbar,
  EditButton,
  ReferenceField,
  NumberField,
  Labeled,
  useUpdate,
  useRecordContext,
} from "react-admin";

import UserNameField from "../../components/user-name-field";
import BookingStatusField from '../../components/booking-status-field';
import ServiceNameField from '../../components/service-name-field';

const eventUpdateInputFields = [
  'weekdays',
  'startDate',
  'endDate',
  'startTime',
  'endTime',
  'isPendingCnfirmation',
  'recurrence',
  'isCancelled'
]

const styles = {
  inline: { display: 'inline-block', marginRight: 5 },
  extraCard: { minWidth: 550, marginBottom: 10, marginTop: 10 },
}

const useStyles = makeStyles(styles);


const ModificationField = ({ originalSource, newSource, label, fromFormat, toFormat }) => {
  const record = useRecordContext();
  const original = get(record, originalSource);
  const modified = get(record, newSource);
  if (original === modified) {
    return <></>
  }
  return <Labeled label={label} fullWidth><span><span style={{ textDecoration: 'line-through' }}>{moment(original, fromFormat).format(toFormat)}</span> <span style={{ color: 'red' }}>{moment(modified, fromFormat).format(toFormat)}</span><br /></span></Labeled>;
};

const ModificationInfo = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const { event, exceptionDate, startTime, endTime, startDate } = record || { event: {} };
  
  if (!exceptionDate || (startTime === event.startTime && endTime === event.endTime && startDate === exceptionDate)) {
    return <></>;
  }
  return (
    <Card raised={true} className={classes.extraCard}>
      <CardHeader title="Session modified from original booking" />
      <CardContent>
        <ModificationField label="Date" originalSource="exceptionDate" newSource="startDate" fromFormat="YYYY-MM-DD" toFormat="LL" />
        <ModificationField label="Start Time" originalSource="event.startTime" newSource="startTime" fromFormat="HH:mm" toFormat="h:mma" />
        <ModificationField label="End Time" originalSource="event.endTime" newSource="endTime" fromFormat="HH:mm" toFormat="h:mma" />
      </CardContent>
    </Card>
  );
}

const ApproveButton = () => {
  const record = useRecordContext();
  const [approve, { isLoading }] = useUpdate('EventInstance', {
    id: record.id,
    data: {
      event: {
        ...pick(record.event || {}, eventUpdateInputFields),
        isPendingConfirmation: false
      }
    },
    previousData: record
  }, { mutationMode: 'pessimistic', onSuccess: data => {
    console.log('REFRESH', data)
  }});
  
  return (
    <Button color="primary" size="small" onClick={() => approve()} disabled={isLoading}>
      <ThumbUp xs={{ fs: "1.25rem" }} style={{ color: 'green', paddingRight: '0.3em' }}/> Approve Booking
    </Button>
  );
};

const RejectButton = () => {
  const record = useRecordContext();
  const [reject, { isLoading }] = useUpdate('EventInstance', {
    id: record.id,
    data: {
      event: {
        ...pick(record.event || {}, eventUpdateInputFields),
        isPendingConfirmation: false,
        isCancelled: true,
      }
    },
    previousData: record
  });

  return (
    <Button color="primary" size="small" onClick={() => reject()} disabled={isLoading}>
      <ThumbDown xs={{ fs: "1.25rem" }} style={{ color: 'red', paddingRight: '0.3em' }}/> Reject Booking
    </Button>
  );
};

const ApproveModificationButton = () => {
  const record = useRecordContext();
  // const [approve, { isLoading }] = useUpdate('EventInstance', record.id, { id: record.id, isPendingConfirmation: false }, record);
  const [approve, { isLoading }] = useUpdate('EventInstance', {
    id: record.id,
    data: { isPendingConfirmation: false },
    previousData: record
  });

  return (
    <Button color="primary" size="small" onClick={() => approve()} disabled={isLoading}>
      <ThumbUp xs={{ fs: "1.25rem" }} style={{ color: 'green', paddingRight: '0.3em' }}/> Approve Modification
    </Button>
  );
};
const RejectModificationButton = () => {
  const record = useRecordContext();
  // const [reject, { isLoading }] = useUpdate('EventInstance', record.id, {id: record.id, isPendingConfirmation: false, isCancelled: true }, record);
  const [reject, { isLoading }] = useUpdate('EventInstance', {
    id: record.id,
    data: { isPendingConfirmation: false, isCancelled: true },
    previousData: record
  });
  return (
    <Button color="primary" size="small" onClick={() => reject()} disabled={isLoading}>
      <ThumbDown xs={{ fs: "1.25rem" }} style={{ color: 'red', paddingRight: '0.3em' }}/> Reject Modification
    </Button>
  );
};

const EventShowActions = () => {
  const record = useRecordContext();

  return (
    <TopToolbar>
      <EditButton />
      {record && record.isPendingConfirmation && (
        record.event.isPendingConfirmation
        ? <>
          <ApproveButton />
          <RejectButton />
          </> 
        : <>
          <ApproveModificationButton />
          <RejectModificationButton />
        </>)}
      {/* <Button color="primary" size="small">
      <MailOutline fontSize="small" /> Send Provider
    </Button>
    <Button color="primary" size="small">
      <MailOutline fontSize="small" /> Send Customer
    </Button> */}
    </TopToolbar>
  )
};

const RecurrenceInfo = () => {
  const { event } = useRecordContext();
  if (!event || !event.recurrence || Object.keys(event.recurrence).length === 0) {
    return 'Single Session';
  }
  const { startDate, endDate, weekdays = [] } = event;

  const { type, interval } = event.recurrence;
  if (type !== 'weekly') {
    throw new Error('unsupported recurrence type');
  }

  const remainingWeekdays = [...weekdays];
  const lastWeekday = remainingWeekdays.pop();
  const recurrenceString = interval > 1 ? ` ${interval} weeks on` : '';
  const weekdayString = weekdays.length > 1 ? `${remainingWeekdays.join(', ')} and ${lastWeekday}` : lastWeekday;
  let message = `Recurs every${recurrenceString} ${weekdayString} `;
  if (endDate && moment(endDate).isValid()) {
    message += `between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`;
  }
  else {
    message += `starting ${moment(startDate).format('LL')}`;
  }

  return message;
};


const EventShow = (props) => {
  const classes = useStyles();
  return (
    <Show title={<EventInstanceTitle />} actions={<EventShowActions />} {...props}>
      <SimpleShowLayout>
        <Box component="div" sx={{ display: 'inline-block', pt: 1 }}>
          <ServiceNameField label="Service Name" className={classes.inline} />
          <BookingStatusField  className={classes.inline} />
        </Box>
        <ConditionalField comp={TextField} label="Title" source="title" />
        <ReferenceField label="Customer" link="show" source="customer.id" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <ReferenceField label="Provider" link="show" source="provider.id" reference="Provider">
          <UserNameField />
        </ReferenceField>

        <StartDateField label="Start" />
        <EndDateField label="End" />
        <ConditionalField comp={TextField} label="Shared Notes (visible to customer and provider)" source="notes" />
        <ConditionalField comp={TextField} label="Dial-in Info" source="dialInInfo" />
        <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} label="Hourly Price Override" source="hourlyPrice" />
        <ConditionalField comp={TextField} label="Payment Method" source="paymentMethod" />

        <RecurrenceInfo addLabel={true} label="Recurrence Info" />
        <ModificationInfo />
      </SimpleShowLayout>
    </Show>
  );
};

export default EventShow;
