import {
  useShowContext,
  Labeled,
  FunctionField,
  useGetOne
} from "react-admin";
import { Box, Stack, Divider } from "@mui/material";

const meta = {
  sparseFields: [
    "id",
    {
      reviews: [
        "id",
        "clientId",
        "clientUserName",
        "createdTimestamp",
        "providerId",
        "review",
      ],
    },
  ],
};

const ReviewsField = () => {
  const { record, resource } = useShowContext();
  const id = record.id;

  const { data: provider, isPending, error } = useGetOne(
    resource,
    { id, meta },
  );

  if (isPending) {
    return <div>Loading...</div>;
  }

  if (error) {
    throw error;
  }

  return (
    <Stack sx={{ gap: '12px' }}>
      {(provider.reviews || []).map((item) => (
        <Stack key={item.id} sx={{ gap: "12px" }}>
          <Box sx={{ display: "flex", gap: "32px" }}>
            <Labeled label="Customer Name">
              <FunctionField
                source="reviews.clientUserName"
                render={() =>
                  item["clientUserName"] ? item["clientUserName"] : "-"
                }
              />
            </Labeled>
            <Labeled label="Added on">
              <FunctionField
                source="reviews.createdTimestamp"
                render={() =>
                  item["createdTimestamp"]
                    ? new Date(+item["createdTimestamp"]).toDateString()
                    : "-"
                }
              />
            </Labeled>
          </Box>

          <Labeled label="Review">
            <FunctionField
              source="reviews.review"
              render={() => (item["review"] ? item["review"] : "-")}
            />
          </Labeled>
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};

export default ReviewsField;
