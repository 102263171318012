import { Chip } from '@mui/material'

import LANGUAGES from '../utils/languages'

const LanguageNameField = ({ languageName }) => {
  let label;
  
  if(languageName === 'en') {
    label = 'English';
  } else {
    const selected = LANGUAGES.filter(lang => lang.value === languageName);
    label = selected[0]?.title;
  }

  return <Chip label={label} />;
};

export default LanguageNameField;
