import { useRef } from 'react';
import { useInput } from "react-admin";
import { Autocomplete, TextField, Box } from "@mui/material";

const IdArrayInput = ({ source, placeholder, label, sx = {} }) => {
  const { field } = useInput({ source });
  const inputRef = useRef();

  if (!field.value instanceof Array) {
    throw new Error(`Value source has to be an array`);
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.defaultMuiPrevented = true;

      if (inputRef.current.value && !isNaN(inputRef.current.value)) { 
        field.onChange([...field.value, parseInt(inputRef.current.value)]);
      }
    }
  }

  const onChange = (event, value, reason) => {
    if (reason === "removeOption") {
      field.onChange(value);
    }
  }

  return (
    <Box sx={{ ...sx }}>
      <Autocomplete
        multiple
        handleHomeEndKeys
        freeSolo
        open={false}
        clearOnBlur
        size="small"
        options={[]}
        value={field.value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            label={label}
            variant="standard"
            inputRef={inputRef}
            {...params}
            placeholder={placeholder}
          />
        )}
      />
    </Box>
  );
};

export default IdArrayInput;
