import {
  useList,
  Datagrid,
  TextField,
  useRecordContext,
  ReferenceField,
  ListContextProvider,
  NumberField,
  DateField,
} from "react-admin";
import { Link } from 'react-router-dom';

import StartDateField from '../../../components/start-date-field';
import EndDateField from '../../../components/end-date-field';
import BookingStatusField from '../../../components/booking-status-field';
import UserNameField from "../../../components/user-name-field";

const SessionLinkField = () => {
  const { instanceId } = useRecordContext();

  return <Link to={`/EventInstance/${instanceId}`}>View</Link>;
};

const SessionTotalField = () => {
  return "0.0";
};

const PayrollSessionsInfo = () => {
  const { sessions } = useRecordContext()
  const listContext = useList({ data: sessions });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <StartDateField label="Start" />
        <EndDateField label="End" />
        <ReferenceField label="Customer" source="customerId" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <NumberField label="Hours" source="hours" />
        <TextField source="hourlyPrice" label="Hourly Price" />
        <BookingStatusField label="Status" />
        <DateField label="Created On" source="createdTimestamp" />
        <NumberField label="Twins Rate Cof." source="applyTwinsRate" />
        <SessionLinkField label="Session" />
        <SessionTotalField />
      </Datagrid>
    </ListContextProvider>
  )
}

export default PayrollSessionsInfo