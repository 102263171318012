import { Tab, TextField, DateField, EmailField } from "react-admin";

const TabBasics = (props) => (
  <Tab label="Basics" {...props}>
    <TextField source="firstName" label="First Name" />
    <TextField source="lastName" label="Last Name" />
    <EmailField source="email" label="E-mail" />
    <TextField source="phoneNumber" label="Phone Number" />
    <TextField source="gender" label="Gender" />
    <DateField source="dateOfBirthTimestamp" label="Date of Birth" />
    <TextField source="billingMode" label="Billing Mode" />
    <TextField source="personalReferralCode" label="Referral Code" />
  </Tab>
);

export default TabBasics;
