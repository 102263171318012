import { Tab, TextField, BooleanField, FunctionField } from "react-admin";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";

import { LanguageNameArrayField } from "../components";
import GeolocationField from "../../../../components/geolocation-field";
import OperationRadiusField from "../../../../components/operation-radius-field";

const styles = {
  inline: { display: "inline-block" },
  chip: { marginRight: 3 },
};
const useStyles = makeStyles(styles);

const TabMatchingCriteria = (props) => {
  const classes = useStyles();

  return (
    <Tab label="Matching Criteria" {...props}>
      <BooleanField
        source="allowsDogs"
        label="Dogs OK?"
        className={classes.inline}
      />
      <BooleanField
        source="allowsCats"
        label="Cats OK?"
        className={classes.inline}
      />
      <BooleanField
        source="allowsSmoking"
        label="Smoking OK?"
        className={classes.inline}
      />
      <BooleanField
        source="hasCar"
        label="Has Car?"
        className={classes.inline}
      />
      <BooleanField
        source="hasSpecialNeedsExperience"
        label="Has Special Needs Experience"
        className={classes.inline}
      />
      <TextField
        source="childcareAgeRange.min"
        label="Minimum Age"
        className={classes.inline}
      />
      <TextField
        source="childcareAgeRange.max"
        label="Maximum Age"
        className={classes.inline}
      />
      {/* New for becoming-provider */}
      <LanguageNameArrayField
        source="speaksLanguages"
        label="Speaks Languages"
      />
      {/*<TextField source="multipleSiblingsExperience" label="Care experience" className={classes.inline} />*/}
      <TextField
        source="reliabilityScore"
        label="Reliability Score"
        className={classes.inline}
      />
      <Divider />
      <TextField label="Zipcode" source="address.zipcode" />
      <TextField label="State" source="address.state" />
      <FunctionField
        label="Commute time"
        render={({ desirableCommutingTime }) =>
          desirableCommutingTime ? `${desirableCommutingTime} minutes` : "-"
        }
      />
      <OperationRadiusField />
      <GeolocationField />
      <FunctionField
        label="Geolocation Link"
        render={({ geoLocation }) =>
          geoLocation ? (
            <a
              target="blank"
              href={`https://maps.google.com/?q=${geoLocation.lat},${geoLocation.lng}`}
            >
              See on the map
            </a>
          ) : (
            "-"
          )
        }
      />
    </Tab>
  );
};

export default TabMatchingCriteria;
