import {
  SelectInput,
  Form,
  SaveButton,
  NumberInput,
  CheckboxGroupInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  ResourceContextProvider,
  Labeled
} from "react-admin";
import { Box, Typography, Stack } from "@mui/material";

import { GeolocationInput, IdArrayInput } from "../../../../components";
import { enrichmentActivitiesList, serviceNameList, dateToTimestamp } from "../../../../utils";

export const HardCriteriaPanel = ({ defaultCriteria, matchByHardCirteria }) => {
  return (
    <Stack>
      <Typography gutterBottom variant="h6" component="div">
        Hard Criteria
      </Typography>
      <Form
        resetOptions={{
          values: defaultCriteria,
        }}
        defaultValues={defaultCriteria}
        id="matching-insights"
        onSubmit={(values) => {
          matchByHardCirteria(values);
        }}
      >
        <Stack>
          <SelectInput
            required
            source="serviceName"
            size="small"
            choices={[...serviceNameList]}
            label="Event Type"
          />
          <Box sx={{ display: "flex", gap: "16px" }}>
            <NumberInput min="0" source="numDogs" label="Dogs" />
            <NumberInput min="0" source="numCats" label="Cats" />
          </Box>
          <CheckboxGroupInput
            label="Enrichment Activities"
            source="enrichmentActivities"
            choices={enrichmentActivitiesList}
          />

          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "3fr 2fr",
            }}
          >
            <ResourceContextProvider value="matching-insights">
              <ArrayInput source="children" label="Children">
                <SimpleFormIterator>
                  <DateInput
                    required
                    source="birthdayTimestamp"
                    label="Birthday"
                    parse={dateToTimestamp}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </ResourceContextProvider>

            <Labeled label="Children With Special Needs" component="div">
              <BooleanInput
                source="specialNeeds"
                label="Children With Special Needs"
              />
            </Labeled>
          </Box>

          <Labeled label="Geolocation" component="div">
            <GeolocationInput
              source="geoLocation"
              sx={{ marginBottom: "16px", width: "512px" }}
            />
          </Labeled>

          <Box sx={{ display: "flex", gap: "16px" }}>
            <IdArrayInput
              source="excludeProviders"
              label="Exclude Providers"
              sx={{ marginBottom: "16px", width: "512px" }}
            />
            <IdArrayInput
              source="restrictToProviders"
              label="Restrict To Providers"
              sx={{ marginBottom: "16px", width: "512px" }}
            />
          </Box>

          <Box>
            <SaveButton label="Match" form="matching-insights" />
          </Box>
        </Stack>
      </Form>
    </Stack>
  );
};
