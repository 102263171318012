import { FunctionField, Labeled } from "react-admin";

const GeolocationField = ({ labeled = true }) => {
  const field = (
    <FunctionField
      render={({ geoLocation }) =>
        geoLocation ? `lat: ${geoLocation.lat} lng: ${geoLocation.lng}` : "-"
      }
    />
  );
  
  if (!labeled) {
    return field 
  }

  return <Labeled label="Geolocation">{field}</Labeled>;
};

export default GeolocationField;
