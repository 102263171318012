import { useRecordContext } from 'react-admin';

const SimpleTextField = ({ defaultText, source }) => {
  const record = useRecordContext();
  
  if (!record || !record[source]) {
    return defaultText ?? '';
  }
  return record[source];
};

export default SimpleTextField;
