import React from "react";
import {
  Edit,
  SaveButton,
  Toolbar,
  DeleteButton
} from "react-admin";
import CreateEditForm from './CreateEditForm';
import { makeStyles } from '@mui/styles'
import EventInstanceTitle from '../../components/event-instance-title';

const styles = {
  flexbox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between'
  },
};

const useStyles = makeStyles(styles);


const CustomToolbar = props => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.flexbox}>
      <SaveButton />
      <DeleteButton undoable={false} label="Cancel Session" confirmTitle="Are you sure you want to cancel this session?" confirmContent=""/>
     </Toolbar>
   );
}


const EventEdit = (props) => (
  <Edit undoable={false} title={<EventInstanceTitle />} {...props}>
    <CreateEditForm toolbar={<CustomToolbar />} />
  </Edit>
);

export default EventEdit;
