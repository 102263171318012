import moment from 'moment';
import { useRecordContext } from 'react-admin';

const EndDate = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return moment(`${record.startDate} ${record.startTime}`).add(record.hours, 'hours').format('LL h:mma');
};

export default EndDate;
