import { ArrayField, useRecordContext } from "react-admin";

const IdToArrayField = (props) => {
  const record = useRecordContext();

  const { source } = props;
  const val = (record[source] || []).map((mt) => ({ id: mt }));

  return (
    <ArrayField {...props} addLabel record={{ ...record, [source]: val }} />
  );
};

export default IdToArrayField;
