import { Edit } from "react-admin";
import CreateEditForm from "./CreateEditForm";

const UserEdit = (props) => (
  <Edit {...props} undoable={false}>
    <CreateEditForm isEdit={true} />
  </Edit>
);

export default UserEdit;
