import { Tab, TextField, Datagrid, ArrayField } from "react-admin";

const TabContacts = (props) => (
  <Tab label="Contacts" {...props}>
    <TextField source="partner.firstName" label="Partner First Name" />
    <TextField source="partner.lastName" label="Partner Last Name" />
    <TextField source="partner.email" label="Partner E-mail" />
    <TextField source="partner.phoneNumber" label="Partner Phone Number" />
    <ArrayField source="emergencyContacts" addLabel={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="relationship" label="Relationship" />
        <TextField source="phoneNumber" label="Phone Number" />
      </Datagrid>
    </ArrayField>
  </Tab>
);

export default TabContacts;
