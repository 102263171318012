import { useEffect } from 'react';
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  TextInput,
  RadioButtonGroupInput,
  BooleanInput,
  TabbedForm,
  NumberInput,
  FormTab,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
  useRecordContext,
} from 'react-admin'

import { DateInput } from '../../components/_DateInput'
import OperationRadiusInput from '../../components/operation-radius-input'
import { serviceNameList, enrichmentActivitiesList } from '../../utils'

const styles = {
  twoColLeft: { display: 'inline-block' },
  twoColRight: { display: 'inline-block', marginLeft: 32 },
}

const useStyles = makeStyles(styles);

const dateToTimestamp = (date) => {
  return +new Date(date);
}

const uploadFileToS3 = async (file, callback) => {
  const initiateResponse = await fetch(`${process.env.REACT_APP_API_URL}user/123/photos/initiate-upload?contentType=${file.type}`, {
    method: 'post',
  });
  const { writeUrl, publicUrl } = await initiateResponse.json();
  const headers = new Headers();
  
  headers.append('x-amz-security-token', process.env.X_AMZ_SECURITY_TOKEN);
  headers.append('Content-Type', file.type)

  await fetch(writeUrl, {
    method: 'put',
    headers,
    body: file,
  });

  if (callback) {
    callback(publicUrl);
  }
}


const UserForm = ({ profilePic, setProfilePic, isEdit, ...props }) => {
  const classes = useStyles();
  const record = useRecordContext();

  useEffect(() => {
    setProfilePic(record.profilePic);
  }, [record?.profilePic, setProfilePic]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Basics">
        <ImageInput
          source="picture"
          label="Photo"
          accept="image/*"
          onChange={(file) => uploadFileToS3(file, setProfilePic)}
        >
          <Box sx={{ width: 150, '& img': { width: '100%' } }}>
            <img src={profilePic} title="Avatar" alt="profile avatar" />
          </Box>
        </ImageInput>

        <FormDataConsumer>
          {({ formData }) =>
            !formData.src && (
              <div>
                <Labeled label="Original image">
                  <ImageField
                    source="profilePic"
                    sx={{
                      "& .RaImageField-image": {
                        width: 180,
                        height: "auto",
                        objectFit: "contain",
                      },
                    }}
                  />
                </Labeled>
              </div>
            )
          }
        </FormDataConsumer>

        <BooleanInput source="isActive" label="Active?" />
        <TextInput
          source="firstName"
          formClassName={classes.twoColLeft}
          label="First Name"
        />
        <TextInput
          source="lastName"
          formClassName={classes.twoColRight}
          label="Last Name"
        />
        {!isEdit && <TextInput source="email" label="E-mail" />}
        <TextInput source="phoneNumber" label="Phone Number" />
        <RadioButtonGroupInput
          source="gender"
          choices={[
            { id: "male", name: "Male" },
            { id: "female", name: "Female" },
          ]}
        />
        <DateInput source="dateOfBirthTimestamp" parse={dateToTimestamp} />

        <hr />
        <TextInput source="zelleEmail" label="Zelle E-mail" />
        <TextInput source="zellePhoneNumber" label="Zelle Phone Number" />
      </FormTab>

      <FormTab label="Profile">
        <CheckboxGroupInput
          label="Services"
          source="services"
          choices={serviceNameList}
        />
        <CheckboxGroupInput
          label="Enrichment Activities"
          source="enrichmentActivities"
          choices={enrichmentActivitiesList}
        />

        <TextInput
          multiline
          fullWidth
          source="blurbs.childcare"
          label="Blurb (Childcare)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.childcarePlus"
          label="Blurb (Enrichment)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.virtual"
          label="Blurb (Virtual)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.cleaning"
          label="Blurb (Cleaning)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.nightNurse"
          label="Blurb (Night Nurse)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.cooking"
          label="Blurb (Cooking)"
        />
        <TextInput
          multiline
          fullWidth
          source="blurbs.housekeeping"
          label="Blurb (Housekeeping)"
        />

        <TextInput
          multiline
          fullWidth
          source="about.childcare"
          label="About (Childcare)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.childcarePlus"
          label="About (Enrichment)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.virtual"
          label="About (Virtual)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.cleaning"
          label="About (Cleaning)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.nightNurse"
          label="About (Night Nurse)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.cooking"
          label="About (Cooking)"
        />
        <TextInput
          multiline
          fullWidth
          source="about.housekeeping"
          label="About (Housekeeping)"
        />

        <TextInput multiline fullWidth source="funFacts" label="Fun Facts" />

        <DateInput
          source="lastBackgroundCheck"
          label="Last Background Check"
          parse={dateToTimestamp}
        />

        {/* New for becoming-provider */}
        <TextInput
          multiline
          fullWidth
          source="extraService"
          label="Extra Help you can offer families"
        />

        <ArrayInput source="certificates">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <DateInput
              source="timestamp"
              label="Date"
              parse={dateToTimestamp}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Matching Criteria">
        <BooleanInput source="allowsDogs" label="Dogs OK?" />
        <BooleanInput source="allowsCats" label="Cats OK?" />
        <BooleanInput source="allowsSmoking" label="Smoking OK?" />
        <BooleanInput source="hasCar" label="Has car?" />
        <BooleanInput
          source="hasSpecialNeedsExperience"
          label="Has Special Needs Experience"
        />
        <NumberInput
          source="childcareAgeRange.min"
          label="Childcare Min Age"
          formClassName={classes.twoColLeft}
        />
        <NumberInput
          source="childcareAgeRange.max"
          label="Childcare Max Age"
          formClassName={classes.twoColRight}
        />
        <NumberInput source="reliabilityScore" label="Reliabilty Score" />
        <OperationRadiusInput
          source="operationRadius"
          label="Operation radius"
        />
      </FormTab>
      <FormTab label="Emergency Contacts">
        <ArrayInput source="emergencyContacts">
          <SimpleFormIterator>
            <TextInput source="name" label="Name" />
            <TextInput source="relationship" label="Relationship" />
            <TextInput source="phoneNumber" label="Phone Number" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Admin Notes">
        <TextInput
          fullWidth
          multiline
          source="adminNotes"
          label="Admin Notes"
        />
      </FormTab>
    </TabbedForm>
  );
};

export default UserForm
