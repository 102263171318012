import {
  ShowContextProvider,
  Labeled,
  ArrayField,
  Datagrid,
  DateField,
  ResourceContextProvider,
  ReferenceField,
} from "react-admin";
import { Stack, Box, Divider } from "@mui/material";

import {
  SimpleTextField,
  TruthyField,
  UserNameField,
  GeolocationField,
  IdToArrayField,
} from "../../../../components";

export const CustomerInfo = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }

  return (
    <Stack sx={{ display: "flex", gap: "8px", padding: "24px 0" }}>
      <Divider sx={{ marginBottom: "10px" }} />
      <ShowContextProvider value={{ record, ...rest }}>

        <Box sx={{ display: "flex", gap: "24px" }}>
          <Labeled label="Customer ID">
            <SimpleTextField source="shortUserId" defaultText="0" />
          </Labeled>

          <Labeled label="Full Name">
            <UserNameField />
          </Labeled>

          <Labeled label="Number of dogs">
            <SimpleTextField source="numDogs" defaultText="0" />
          </Labeled>

          <Labeled label="Number of cats">
            <SimpleTextField source="numCats" defaultText="0" />
          </Labeled>

          <Labeled label="Special Needs">
            <TruthyField source="specialNeeds" />
          </Labeled>

          <GeolocationField />
        </Box>

        <Box>
          <Labeled label="Email">
            <SimpleTextField source="email" defaultText="-" />
          </Labeled>
        </Box>

        <Box sx={{ display: "flex", gap: "24px" }}>
          <ResourceContextProvider value="Children">
            <Labeled label="Children">
              <ArrayField source="children">
                <Datagrid bulkActionButtons={false}>
                  <SimpleTextField source="Age" defaultText="-" />
                  <DateField
                    source="birthdayTimestamp"
                    label="Birthday (approx)"
                  />
                </Datagrid>
              </ArrayField>
            </Labeled>
          </ResourceContextProvider>

          <ResourceContextProvider value="Team">
            <Labeled label="Customer's team">
              <IdToArrayField source="myTeam">
                <Datagrid bulkActionButtons={false}>
                  <SimpleTextField label="ID" source="id" defaultText="-" />
                  <ReferenceField
                    source="id"
                    reference="Provider"
                    link="show"
                    label="Provider"
                  >
                    <UserNameField />
                  </ReferenceField>
                </Datagrid>
              </IdToArrayField>
            </Labeled>
          </ResourceContextProvider>
        </Box>

      </ShowContextProvider>
    </Stack>
  );
};
