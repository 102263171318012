import React from "react";
import { format } from 'date-fns'
import jsonExport from 'jsonexport/dist';
import {
  EditButton,
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  downloadCSV,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from "react-admin";
import PaginationOptions from '../../components/pagination-options';

const customerExporter = (customers) => {
  const postsForExport = customers.map((customer, index) => {
    const { created, children, tosHistory, ...customerForExport } = customer;
    
    return {
      index: index + 1,
      ...customerForExport,
      children: (children || []).map(({ name, gender, birthdayTimestamp }) => ({
        name,
        gender,
        birthday: format(birthdayTimestamp, "MM/dd/yyyy")
      })),
      tosHistory: (tosHistory || []).map(({ timestamp, ...record }) => ({
        ...record,
        date: format(timestamp, "MM/dd/yyyy")
      })),
      created: format(created, "MM/dd/yyyy"),
    };
  });

  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, "customers");
  });
};

const CustomerListActions = () => (
  <TopToolbar>
      <CreateButton />
      <FilterButton />
      <ExportButton maxResults={10000} />
  </TopToolbar>
);

const postFilters = [
  <TextInput source="query" label="Customer Name" alwaysOn />,
  <TextInput label="E-mail" source="email" alwaysOn/>
];

const UserList = (props) => {
  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      filters={postFilters}
      sort={{ field: "created", order: "DESC" }}
      exporter={customerExporter}
      actions={<CustomerListActions />}
    >
      <Datagrid rowClick="show">
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" label="E-mail" />
        <TextField source="address.zipcode" label="Zipcode" />
        <TextField source="phoneNumber" />
        <DateField source="created" label="Member Since" />
        <TextField source="adminNotes" label="Admin Notes" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
