import UserEdit from './edit'
import UserList from './list'
import UserShow from './show'
import UserCreate from './create'

const userComponents = {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
}

export default userComponents
