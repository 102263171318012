import { useInput } from "react-admin";
import { Box, TextField } from "@mui/material";

const minLatitude = -90;
const maxLatitude = 90;

const minLongitude = -180;
const maxLongitude = 180;

const GeolocationInput = ({ source, sx = {} }) => {
  const { field } = useInput({ source });
  const { lat, lng } = field.value ?? { lat: 0, lng: 0 };

  return (
    <Box sx={{ display: "flex", gap: '12px', ...sx }}>
      <TextField
        label="Latitude"
        variant="filled"
        value={lat}
        InputProps={{ inputProps: { min: minLatitude, max: maxLatitude } }}
        onChange={(event) => field.onChange({ lat: event.target.value, lng })}
      />
      <TextField
        label="Longitude"
        variant="filled"
        value={lng}
        InputProps={{ inputProps: { min: minLongitude, max: maxLongitude } }}
        onChange={(event) => field.onChange({ lat, lng: event.target.value })}
      />
    </Box>
  );
};

export default GeolocationInput;
