import { Create } from "react-admin";
import CreateEditForm from "./CreateEditForm";

const transformFormValues = (values) => {
  if (values.hourlyPrice === null) {
    delete values.hourlyPrice;
  }

  if (!values.event?.endDate) {
    delete values.event;
  }

  return values;
};

const EventCreate = (props) => (
  <Create {...props} transform={transformFormValues}>
    <CreateEditForm redirect="show" />
  </Create>
);

export default EventCreate;
