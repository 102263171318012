import { Chip } from '@mui/material'
import { useRecordContext } from 'react-admin';
import { Help, Cancel, CheckCircle } from '@mui/icons-material'

const BookingStatusField = ({ _, ...props }) => {
  const record = useRecordContext()
  let status = "Confirmed";
  let icon = <CheckCircle />;

  if (!record) {
    return <></>;
  }

  if (record.isCancelled) {
    status = "Cancelled";
    icon = <Cancel />;
  } else if (record.isPendingConfirmation) {
    status = "Pending";
    icon = <Help />;
  }
  
  return (
    <span {...props}><Chip label={status} icon={icon}/></span>
  )
};

export default BookingStatusField;
