import React from "react";
import moment from 'moment';
import { Card, CardContent } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Timer from "@mui/icons-material/Timer";

import {
  Datagrid,
  List,
  Filter,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  FilterList,
  FilterListItem,
  NumberField,
  SelectInput,
  DateField,
} from "react-admin";

import {
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  addMonths,
  format,
} from "date-fns";

import { DateInput } from '../../components/_DateInput';
import UserNameField from "../../components/user-name-field";
import BookingStatusField from '../../components/booking-status-field';
import ServiceNameField from '../../components/service-name-field';
import StartDateField from '../../components/start-date-field';
import EndDateField from '../../components/end-date-field';
import PaginationOptions from '../../components/pagination-options';
import { eventTypeList } from '../../utils';


const DATE_FORMAT = "yyyy-MM-dd";

const getOptionText = (r) => {
  if (!r || !r?.id) return null;
  let name = r.firstName;
  if (r.lastName) name += ` ${r.lastName}`;
  return name;
};

const EventListFilter = (props) => {
  return (
    <Filter {...props}>
      <DateInput source="startDate_gte" label="Starts on or After" alwaysOn/>
      <DateInput source="startDate_lte" label="Starts on or Before" alwaysOn />
      <ReferenceInput
        label="Customer"
        source="customerId"
        filter={{ userRole: "customer" }}
        sort={{ field: "firstName", order: "DESC" }}
        reference="Customer"
        filterToQuery={(query) => ({ query })}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput
          shouldRenderSuggestions={(val) => val.length >= 3}
          optionText={getOptionText}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Provider"
        source="providerId"
        filter={{ userRole: "provider" }}
        sort={{ field: "firstName", order: "DESC" }}
        reference="Provider"
        filterToQuery={(query) => ({ query })}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput
          shouldRenderSuggestions={(val) => val.length >= 3}
          optionText={getOptionText}
        />
      </ReferenceInput>
      <SelectInput source="eventType" choices={[...eventTypeList]} label="Event Type" alwaysOn />
    </Filter>
  );
};



const StatusFilter = () => (
  <FilterList label="Status" icon={<Timer />}>
    <FilterListItem
      label="Pending"
      value={{ isPendingConfirmation: true }}
    />
  </FilterList>
);


const StartDateFilter = () => (
  <FilterList label="Date" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        startDate_gte: format(endOfYesterday(), DATE_FORMAT),
        startDate_lte: format(new Date(), DATE_FORMAT),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        startDate_gte: format(startOfWeek(new Date()), DATE_FORMAT),
        startDate_lte: format(endOfWeek(new Date()), DATE_FORMAT),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        startDate_gte: format(startOfMonth(new Date()), DATE_FORMAT),
        startDate_lte: format(endOfMonth(new Date()), DATE_FORMAT),
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        startDate_gte: format(
          subMonths(startOfMonth(new Date()), 1),
          DATE_FORMAT
        ),
        startDate_lte: format(startOfMonth(new Date()), DATE_FORMAT),
      }}
    />
    <FilterListItem
      label="Next month"
      value={{
        startDate_gte: format(endOfMonth(new Date()), DATE_FORMAT),
        startDate_lte: format(
          addMonths(endOfMonth(new Date()), 1),
          DATE_FORMAT
        ),
      }}
    />
  </FilterList>
);

const EventSideBar = () => (
  <div style={{ marginLeft: '16px' }}>
    <Card>
      <CardContent>
        <StatusFilter />
        <StartDateFilter />
      </CardContent>
    </Card>
  </div>
);

const EventList = (props) => {
  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      title="Sessions"
      aside={<EventSideBar />}
      filters={<EventListFilter />}
      sort={{ field: 'createdTimestamp', order: 'DESC' }}
      filterDefaultValues={{
        'eventType': 'appointment',
        'startDate_gte': moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
        'startDate_lte': moment().add(3, 'months').format('YYYY-MM-DD'),
      }}
    >
      <Datagrid rowClick="show">
        <ReferenceField label="Provider" source="provider.id" reference="Provider">
          <UserNameField />
        </ReferenceField>
        <ReferenceField label="Customer" source="customer.id" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <ServiceNameField label="Service" />
        <NumberField label="Hours" source="hours" />
        <StartDateField label="Start" />
        <EndDateField label="End" />
        <DateField label="Created On" source="createdTimestamp" />
        <BookingStatusField label="Status" />
      </Datagrid>
    </List>
  );
};

export default EventList;
