import { Tab, TextField, FunctionField } from "react-admin";

import GeolocationField from "../../../../components/geolocation-field";

const TabAddress = (props) => (
  <Tab label="Address" {...props}>
    <TextField label="Street Address" source="address.streetAddress" />
    <TextField label="Unit" source="address.unit" />
    <TextField label="City" source="address.city" />
    <TextField label="State" source="address.state" />
    <TextField label="Zipcode" source="address.zipcode" />
    <TextField label="Entrance Instructions" source="entranceInstructions" />
    <GeolocationField />
    <FunctionField
      label="Geolocation Link"
      render={({ geoLocation }) =>
        geoLocation ? (
          <a
            target="blank"
            href={`https://maps.google.com/?q=${geoLocation.lat},${geoLocation.lng}`}
          >
            See on the map
          </a>
        ) : (
          "-"
        )
      }
    />
  </Tab>
);

export default TabAddress;
