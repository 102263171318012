import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip'

function humanServiceNameWithEmoji(serviceName, record) {
  if (!serviceName) {
    const { eventType } = record;
    if (eventType === 'interview') return '📱 Interview'
    if (eventType === 'private') return `🕵 Personal`;

    serviceName = record.serviceName;
  }

  if (serviceName === 'night_nurse') return '👶 Night Nurse'
  if (serviceName === 'cleaning') return '🧹 Cleaning'
  if (serviceName === 'deepCleaning') return '🧹 Deep Cleaning'
  if (serviceName === 'childcare') return '👩‍👧‍👦 Childcare'
  if (serviceName === 'childcarePlus') return '🎨 Enrichment'
  if (serviceName === 'virtual') return '👨‍💻 Virtual'
  if (serviceName === 'cooking') return '🍳 Cooking'
  if (serviceName === 'housekeeping') return 'Jill of all Trades'
  return '';

}
const ServiceNameField = ({ serviceName, _, ...props }) => {
  const record = useRecordContext()

  if (!record) {
    return <></>;
  }
  return <span {...props}><Chip label={humanServiceNameWithEmoji(serviceName, record)} /></span>;
};

export default ServiceNameField;
