import {
  Tab,
  TextField,
  DateField,
  ShowButton,
  ReferenceField,
  Pagination,
  ReferenceManyField,
  Datagrid,
} from "react-admin";

import { UserNameField } from "../../../../components";

import ServiceNameField from "../../../../components/service-name-field";
import BookingStatusField from "../../../../components/booking-status-field";

const TabSessions = (props) => (
  <Tab label="Sessions" {...props}>
    <ReferenceManyField
      pagination={<Pagination />}
      reference="EventInstance"
      target="customerId"
      addLabel={false}
      sort={{ field: "createdTimestamp", order: "DESC" }}
    >
      <Datagrid>
        <ReferenceField
          sortable={false}
          label="Provider"
          source="provider.id"
          reference="Provider"
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <TextField source="startDate" sortable={false} />
        <TextField source="startTime" sortable={false} />
        <TextField source="endDate" sortable={false} />
        <TextField source="endTime" sortable={false} />
        <DateField source="createdTimestamp" sortable={false} label="created" />
        <ServiceNameField />
        <BookingStatusField />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  </Tab>
);

export default TabSessions;
