import { Tab, TextField, ArrayField, Datagrid, DateField } from "react-admin";
import { makeStyles } from "@mui/styles";

import { TruthyField } from "../../../../components";

const styles = {
  inline: { display: "inline-block" },
};

const useStyles = makeStyles(styles);

const TabMatchingCriteria = (props) => {
  const classes = useStyles();

  return (
    <Tab label="Matching Criteria" {...props}>
      <TruthyField source="numDogs" label="Dogs" className={classes.inline} />
      <TruthyField source="numCats" label="Cats" className={classes.inline} />
      <TruthyField
        source="smokingHousehold"
        label="Smoking"
        className={classes.inline}
      />
      <TruthyField
        source="specialNeeds"
        label="Special Needs"
        className={classes.inline}
      />
      <TextField source="dietaryRestrictions" label="Dietary Restrictions" />
      <ArrayField source="children" label="Children">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="Name" />
          <DateField source="birthdayTimestamp" label="Birthday (approx)" />
          <TextField source="gender" label="gender" />
        </Datagrid>
      </ArrayField>
    </Tab>
  );
};

export default TabMatchingCriteria;
