import { useRecordContext, BooleanField } from 'react-admin';
import get from "lodash/get";

export const TruthyField = ({ invert, source, ...props }) => {
  const record = useRecordContext();

  const fieldName = `${source}-bool`;
  const val = get(record, source);

  record[fieldName] = invert ? !val : !!val;

  return <BooleanField {...props} source={fieldName} />;
};

export default TruthyField;
