import fetchApi from '../utils/fetch'

const loginApi = data => fetchApi.post('authenticate', data, { publicApi: true })

export const TOKEN_KEY = 'apiari_token'

const authProvider = {
  login: async ({ username, password }) => {
    const { data } = await loginApi({ email: username, password })
    if (!data?.isAdmin) {
      throw new Error('Not an admin');
    }
    if (data?.token) {
      localStorage.setItem(TOKEN_KEY, data.token)
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY)
    return Promise.resolve()
  },
  checkAuth: () => {
    const hasToken = localStorage.getItem(TOKEN_KEY)
    return hasToken ? Promise.resolve() : Promise.reject()
  },
  checkError: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
}

export default authProvider
