import { useCallback, useState } from "react";
import { Create } from "react-admin";
import CreateEditForm from "./CreateEditForm";

const UserCreate = (props) => {
  const [profilePic, setProfilePic] = useState(null);

  return (
    <Create {...props} transform={(x) => ({ ...x, profilePic })}>
      <CreateEditForm
        profilePic={profilePic}
        setProfilePic={useCallback((picture) => setProfilePic(picture), [setProfilePic])}
        isEdit={false}
      />
    </Create>
  );
};

export default UserCreate;
