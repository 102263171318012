import { Show, TabbedShowLayout } from "react-admin";

import TabBasics from "./tabs/tab-basics";
import TabContacts from "./tabs/tab-contacts";
import TabNotifications from "./tabs/tab-notifications";
import TabMyTeam from "./tabs/tab-my-team";
import TabSessions from "./tabs/tab-sessions";
import TabMatchingCriteria from "./tabs/tab-matching-criteria";
import TabAdminNotes from "./tabs/tab-admin-notes";
import TabAddress from "./tabs/tab-address";

import { UserTitle } from "./components";

const UserShow = (props) => {
  return (
    <Show title={<UserTitle />} {...props}>
      <TabbedShowLayout>
        <TabBasics />
        <TabMatchingCriteria />
        <TabAddress />
        <TabSessions />
        <TabContacts />
        <TabNotifications />
        <TabMyTeam />
        <TabAdminNotes />
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
