import { createElement } from 'react';
import { useRecordContext } from "react-admin";
import get from 'lodash/get';

const ConditionalField = (props) => {
  const record = useRecordContext();
  const { comp, children, source } = props;
  let { condition } = props;
  
  if (!condition) {
    condition = (record) => get(record, source);
  }

  if (!condition(record)) {
    return '-';
  }

  return createElement(comp, {...props}, children);

};

export default ConditionalField;
