import * as React from 'react';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import { useInput, FieldTitle, mergeRefs } from 'ra-core';
import { InputHelperText } from 'react-admin';


export const sanitizeInputRestProps = ({
    afterSubmit,
    allowNull,
    alwaysOn,
    beforeSubmit,
    component,
    data,
    defaultValue,
    error,
    format,
    formatOnBlur,
    initialValue,
    initializeForm,
    input,
    isEqual,
    isRequired,
    label,
    limitChoicesToValue,
    locale,
    meta,
    multiple,
    name,
    options,
    optionText,
    optionValue,
    parse,
    record,
    ref,
    refetch,
    render,
    resource,
    setFilter,
    setPagination,
    setSort,
    shouldUnregister,
    source,
    submitError,
    subscription,
    textAlign,
    translate,
    translateChoice,
    validate,
    validateFields,
    value,
    ...rest
}) => rest;

// TODO: This component should be replaced for react-admin DataIput as soon as the patch for the bug is released
// https://github.com/marmelab/react-admin/issues/10197
export const DateInput = ({
    className,
    defaultValue,
    format = getStringFromDate,
    label,
    source,
    resource,
    helperText,
    margin,
    onBlur,
    onChange,
    onFocus,
    parse,
    validate,
    variant,
    disabled,
    readOnly,
    ...rest
}) => {
    const { field, fieldState, id, isRequired } = useInput({
        defaultValue,
        onBlur,
        resource,
        source,
        validate,
        disabled,
        readOnly,
        ...rest,
    });
    const [renderCount, setRenderCount] = React.useState(1);
    const valueChangedFromInput = React.useRef(false);
    const localInputRef = React.useRef();
    const initialDefaultValueRef = React.useRef(field.value);

    React.useEffect(() => {
        const initialDateValue =
            new Date(initialDefaultValueRef.current).getTime() || null;

        const fieldDateValue = new Date(field.value).getTime() || null;

        if (
            initialDateValue !== fieldDateValue &&
            !valueChangedFromInput.current
        ) {
            setRenderCount(r => r + 1);
            parse
                ? field.onChange(parse(field.value))
                : field.onChange(field.value);
            initialDefaultValueRef.current = field.value;
            valueChangedFromInput.current = false;
        }
    }, [setRenderCount, parse, field]);

    const { onBlur: onBlurFromField } = field;
    const hasFocus = React.useRef(false);

    // update the input text when the user types in the input
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
        if (
            typeof event.target === 'undefined' ||
            typeof event.target.value === 'undefined'
        ) {
            return;
        }
        const target = event.target;

        const newValue =
            target.valueAsDate !== undefined &&
            target.valueAsDate !== null &&
            !isNaN(new Date(target.valueAsDate).getTime())
                ? parse
                    ? parse(target.valueAsDate)
                    : getStringFromDate(target.valueAsDate)
                : parse
                  ? parse(target.value)
                  : getStringFromDate(target.value);

        // Some browsers will return null for an invalid date so we only change react-hook-form value if it's not null
        // The input reset is handled in the onBlur event handler
        if (newValue !== '' && newValue != null) {
            field.onChange(newValue);
            valueChangedFromInput.current = true;
        }
    };

    const handleFocus = (event) => {
        if (onFocus) {
            onFocus(event);
        }
        hasFocus.current = true;
    };

    const handleBlur = () => {
        hasFocus.current = false;

        if (!localInputRef.current) {
            return;
        }

        // To ensure users can clear the input, we check its value on blur
        // and submit it to react-hook-form
        const newValue =
            localInputRef.current.valueAsDate !== undefined &&
            localInputRef.current.valueAsDate !== null &&
            !isNaN(new Date(localInputRef.current.valueAsDate).getTime())
                ? parse
                    ? parse(localInputRef.current.valueAsDate)
                    : getStringFromDate(localInputRef.current.valueAsDate)
                : parse
                  ? parse(localInputRef.current.value)
                  : getStringFromDate(localInputRef.current.value);

        if (newValue !== field.value) {
            field.onChange(newValue ?? '');
        }

        if (onBlurFromField) {
            onBlurFromField();
        }
    };
    const { error, invalid } = fieldState;
    const renderHelperText = helperText !== false || invalid;

    const { ref, name } = field;
    const inputRef = mergeRefs([ref, localInputRef]);

    return (
        <TextField
            id={id}
            name={name}
            inputRef={inputRef}
            defaultValue={format(initialDefaultValueRef.current)}
            key={renderCount}
            type="date"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={clsx('ra-input', `ra-input-${source}`, className)}
            size="small"
            variant={variant}
            margin={margin}
            error={invalid}
            disabled={disabled || readOnly}
            readOnly={readOnly}
            helperText={
                renderHelperText ? (
                    <InputHelperText
                        error={error?.message}
                        helperText={helperText}
                    />
                ) : null
            }
            label={
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            }
            InputLabelProps={defaultInputLabelProps}
            {...sanitizeInputRestProps(rest)}
        />
    );
};


/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
// const convertDateToString = (value) => {
//     if (!(value instanceof Date) || isNaN(value.getDate())) return '';
//     const pad = '00';
//     const yyyy = value.getFullYear().toString();
//     const MM = (value.getMonth() + 1).toString();
//     const dd = value.getDate().toString();
//     return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
// };
const convertDateToString = (value) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    let UTCDate = new Date(value.getTime() + value.getTimezoneOffset() * 60000);
    const pad = '00';
    const yyyy = UTCDate.getFullYear().toString();
    const MM = (UTCDate.getMonth() + 1).toString();
    const dd = UTCDate.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
}

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const defaultInputLabelProps = { shrink: true };

const getStringFromDate = (value) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') {
        return null;
    }

    if (value instanceof Date) {
        return convertDateToString(value);
    }

    // valid dates should not be converted
    if (dateRegex.test(value)) {
        return value;
    }

    return convertDateToString(new Date(value));
};
