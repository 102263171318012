import { Show, TabbedShowLayout } from "react-admin";

import TabBasics from "./tabs/tab-basics";
import TabProfile from "./tabs/tab-profile";
import TabLinks from "./tabs/tab-links";
import TabRates from "./tabs/tab-rates";
import TabSessions from "./tabs/tab-sessions";
import TabReviews from "./tabs/tab-reviews";
import TabMatchingCriteria from "./tabs/tab-matching-criteria";
import TabAdminNotes from "./tabs/tab-admin-notes";
import TabEmergencyContacts from "./tabs/tab-emergency-contacts";

const UserTitle = ({ record }) => {
  if (!record) return <span />;
  return (
    <span>
      {" "}
      {record.firstName} {record.lastName} (Provider #{record.id})
    </span>
  );
};

const UserShow = (props) => (
  <Show title={<UserTitle />} {...props}>
    <TabbedShowLayout>
      <TabBasics />
      <TabProfile />
      <TabMatchingCriteria />
      <TabSessions />
      <TabRates />
      <TabEmergencyContacts />
      <TabLinks />
      <TabReviews />
      <TabAdminNotes />
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
